import React from "react";

import {
  ILayoutMarkupData,
  TValidationMarkup,
} from "../../types/commonMarkup.type";
import { ILang } from "../../types/common.type";

import ContentContainer from "../common/contentContainer";
import Requisites from "./requisites";
import PoliciesLinks from "./policiesLinks";
import FooterLinksAndForm from "./footerLinksAndForm";

interface IFooterProps extends ILang {
  markup: ILayoutMarkupData;
  validationMarkup?: TValidationMarkup;
}

const Footer = ({ markup, validationMarkup }: IFooterProps) => {
  const { contactsLabel, links, requisites, policies } = markup;

  return (
    <footer className="bg-main-100 border-t-2 border-secondary-70 border-solid">
      <ContentContainer
        maxWidthClass="max-w-[1280px]"
        className="flex-col flex-col-reverse md:flex-row !lg:px-12 py-10 gap-x-24 gap-y-8 md:justify-between lg:justify-start"
      >
        <div className="flex flex-col gap-4 md:gap-5 w-full sm:w-auto">
          <span className="text-sm sm:text-lg font-bold text-secondary-100">
            {contactsLabel.toUpperCase()}
          </span>
          <Requisites requisites={requisites} />
          <PoliciesLinks policies={policies} />
        </div>

        <FooterLinksAndForm
          links={links}
          markup={markup.getPortfolioForm}
          validationMarkup={validationMarkup}
          className="flex"
        />
      </ContentContainer>
    </footer>
  );
};

export default Footer;
