import React from "react";

import { Skeleton } from "@mui/material";

const SkeletonStub = () => {
  return (
    <Skeleton
      animation="wave"
      variant="rounded"
      width={"100%"}
      height={"100%"}
      sx={{
        position: "absolute",
        inset: "0px",
        zIndex: 9,
        bgcolor: "rgba(0, 0, 0, 0.1)",
        "::after": {
          background:
            "linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent)",
        },
      }}
    />
  );
};

export default SkeletonStub;
