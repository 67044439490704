import React, { useEffect, useState } from "react";

import _ from "lodash";

const ScrollProgress = () => {
  const [scrollProgress, setScrollProgress] = useState<number>(0);

  const onScroll = _.debounce(() => {
    const winScroll = document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = Math.floor((winScroll / height) * 100);

    setScrollProgress(scrolled);
  }, 10);

  useEffect(() => {
    onScroll();
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div className="h-1 w-full bg-secondary-100 absolute bottom-0 left-0">
      <div
        className="h-full w-0 bg-main-100 transition-all ease-linear duration-300"
        style={{
          width: `${scrollProgress}%`,
        }}
      />
    </div>
  );
};

export default ScrollProgress;
