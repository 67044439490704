import { FieldErrors, FieldValues } from "react-hook-form";
import {
  IContactFormData,
  IGetPortfolioFormData,
  IHubspotFormDTO,
  ValidationKeys,
} from "../types/form.type";
import { TValidationMarkup } from "../types/commonMarkup.type";

export const mapGetPorfolioData = (
  data: IGetPortfolioFormData,
  hutk?: string
): IHubspotFormDTO<IGetPortfolioFormData> => {
  return {
    fields: Object.entries(data).map(([key, value]) => ({
      objectTypeId: "0-1",
      name: key as keyof typeof data,
      value: value,
    })),
    context: {
      hutk,
    },
    legalConsentOptions: {
      consent: {
        consentToProcess: true,
        text: "I agree to allow Example Company to store and process my personal data.",
      },
    },
  };
};

export const mapContactFormData = (
  data: IContactFormData,
  hutk?: string
): IHubspotFormDTO<Omit<IContactFormData, "consentToProcess">> => {
  return {
    fields: Object.entries(data)
      .filter(([key, _]) => key !== "consentToProcess")
      .map(([key, value]) => ({
        objectTypeId: "0-1",
        name: key as keyof Omit<typeof data, "consentToProcess">,
        value: value,
      })),
    context: {
      hutk,
    },
    legalConsentOptions: {
      consent: {
        consentToProcess: data.consentToProcess,
        text: "I agree to allow Example Company to store and process my personal data.",
      },
    },
  };
};

export function getValidationMessage<T extends FieldValues>(
  key: keyof T,
  errors: FieldErrors<T>,
  validationMarkup?: TValidationMarkup
): string | undefined | null {
  return errors[key]?.message &&
    Object.values(ValidationKeys).includes(
      // @ts-ignore
      errors[key].message
    )
    ? validationMarkup?.[errors[key]?.message as ValidationKeys]
    : null;
}
