import React from "react";

import { Link } from "gatsby";

import { TLinksMarkup } from "../../types/commonMarkup.type";

const FooterNavLinks = ({
  links,
  className,
}: {
  links: TLinksMarkup;
  className?: string;
}) => {
  return (
    <nav className={`w-full ${className}`}>
      <ul className="w-full flex items-center sm:gap-x-10 lg:gap-x-12 justify-between sm:justify-start">
        {Object.entries(links).map(([link, title], i) => (
          <li key={`mainLinks-${i}`} className="w-max">
            <Link
              to={`/${link.toLowerCase().split(" ").join("-")}`}
              aria-label={`CodeCave ${title.toLowerCase()}`}
              className="text-sm sm:text-lg font-bold text-secondary-100 hover:brightness-75"
            >
              {title.toUpperCase()}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default FooterNavLinks;
