import React, { useEffect, useState } from "react";
import { Link } from "gatsby";

const NavLink = ({
  path,
  link,
  title,
  className,
}: {
  path: string;
  link: string;
  title: string;
  className?: string;
}) => {
  // isCurrent is set by useState + useEffect in order to avoid production errors that ssr styles doesn't match client render
  const [isCurrent, setIsCurrent] = useState<boolean>(false);
  useEffect(() => {
    setIsCurrent(path?.includes(linkPath));
  }, [path]);

  const linkPath = `${link.toLowerCase().split(" ").join("-")}`;

  return (
    <li>
      <Link
        to={`/${linkPath}`}
        aria-label={`CodeCave ${title.toLowerCase()}`}
        itemProp="url"
        className={`group text-lg font-semibold text-main-100 hover:text-main-10 relative ${className}`}
      >
        <span itemProp="name">{title.toLowerCase()}</span>
        <span
          className={`absolute -bottom-1 -left-2 -right-2 h-1 border-b-2 border-solid border-main-100 group-hover:border-main-10 ${
            isCurrent ? "hidden md:block" : "hidden"
          }`}
        />
      </Link>
    </li>
  );
};

export default NavLink;
