import React from "react";

import { IRequisitesMarkup } from "../../types/commonMarkup.type";

import ContactLinks from "./contactLinks";

interface IRequisitesProps {
  requisites: IRequisitesMarkup;
}

const Requisites = ({
  requisites: { ein, companyCode, street, city, postalCode, country, phone },
}: IRequisitesProps) => {
  return (
    <div className="text-xs text-secondary-100 w-full sm:w-max whitespace-pre">
      <p>{`${ein}${!!companyCode ? "," : ""} ${companyCode}`}</p>
      <p className="inline sm:block">{`${street}`}</p>
      <p>{`${city}, ${postalCode}, ${country}`}</p>
      <p className="hidden md:block"> </p>
      <a
        className="block"
        href={`tel:${phone.split(" ").join("")}`}
        title={`call ${phone}`}
      >
        {phone}
      </a>
      {/* Contacts menu */}
      <div
        className="flex flex-wrap justify-around md:justify-start shrink pt-8 gap-x-5 w-full sm:w-auto"
        itemScope
        itemType="https://schema.org/ContactPoint"
        itemProp="contactPoint"
      >
        <ContactLinks invert size={20} />
      </div>
    </div>
  );
};

export default Requisites;
