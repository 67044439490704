import React, { useEffect } from "react";

import { PageProps, graphql, useStaticQuery } from "gatsby";

import { IBasePageContext, ISiteMetadata } from "../../types/common.type";
import {
  ILayoutMarkupData,
  TValidationMarkup,
} from "../../types/commonMarkup.type";

import Navbar from "./navbar";
import Footer from "./footer";
import { BasicMetadata } from "../common/metadata";
import UpButton from "../common/upButton";
import AnalyticsByConsent from "./analyticsByConsent";

const Layout = ({
  path,
  children,
  pageContext: { lang },
}: PageProps<React.PropsWithChildren, IBasePageContext>) => {
  useEffect(() => {
    if (typeof navigator != undefined) {
      if (navigator.userAgent.indexOf("iPhone") > -1) {
        document
          ?.querySelector("[name=viewport]")
          ?.setAttribute(
            "content",
            "width=device-width, initial-scale=1, maximum-scale=1"
          );
      }
    }
  }, []);

  const layoutData = useStaticQuery(query) as {
    site: {
      siteMetadata: ISiteMetadata;
    };
    allMarkdownRemark?: {
      nodes?:
        | {
            fields: { language: string };
            frontmatter: {
              layout: ILayoutMarkupData;
              validation: TValidationMarkup;
            };
          }[]
        | null;
    };
  };

  const layoutMarkupFallback: ILayoutMarkupData = {
    discussProject: "CONTACT US",
    links: {
      services: "Services",
      workflow: "Workflow",
      projects: "Projects",
      blog: "Blog",
    },
    contactsLabel: "CONTACTS",
    requisites: {
      ein: "EIN: 35-2654711",
      companyCode: "Company Code: 7296176",
      street: "8 The Green STE B",
      city: "Dover",
      postalCode: "DE 19901-3618",
      country: "United States",
      phone: "+1 302 364 0193",
    },
    homePageLinkLabel: "CodeCave home page",
    getPortfolioForm: {
      title: "Leave your contact to get our portfolio",
      name: "Name",
      email: "Email*",
      buttonLabel: "get portfolio",
    },
    policies: {
      privacy: {
        title: "Privacy Policy",
        link: "https://www.codecave.it/privacy-policy",
      },
      cookie: {
        title: "Cookie Policy",
        link: "https://www.codecave.it/cookie-policy",
      },
    },
  };
  const allMarkup = layoutData?.allMarkdownRemark?.nodes?.find(
    (node) => node.fields.language === lang
  )?.frontmatter;
  const layoutMarkup = allMarkup?.layout ?? layoutMarkupFallback;
  const validationMarkup = allMarkup?.validation;
  const gtagId = layoutData?.site?.siteMetadata?.analytics?.gtagId;
  const clarityId = layoutData?.site?.siteMetadata?.analytics?.clarityId;

  return (
    <>
      <AnalyticsByConsent gtagId={gtagId} clarityId={clarityId} />
      <main
        className={`h-full min-h-screen bg-secondary-100 flex flex-col`}
        itemScope
        itemType="https://schema.org/LocalBusiness"
      >
        <BasicMetadata lang={lang} />
        <Navbar path={path} layoutMarkup={layoutMarkup} />
        {children}
        <Footer
          lang={lang}
          markup={layoutMarkup}
          validationMarkup={validationMarkup}
        />
        <UpButton />
      </main>
    </>
  );
};

export default Layout;

export const query = graphql`
  query {
    site {
      siteMetadata {
        analytics {
          gtagId
          clarityId
        }
      }
    }
    allMarkdownRemark(
      filter: { fields: { sourceName: { eq: "common" } } }
      sort: { frontmatter: { order: ASC } }
    ) {
      nodes {
        fields {
          language
        }
        frontmatter {
          validation {
            invalidEmail
            required
            consent
          }
          layout {
            links {
              services
              workflow
              projects
              blog
            }
            contactsLabel
            discussProject
            requisites {
              ein
              companyCode
              street
              city
              postalCode
              country
              phone
            }
            homePageLinkLabel
            getPortfolioForm {
              title
              name
              email
              buttonLabel
            }
            policies {
              privacy {
                title
                link
              }
              cookie {
                title
                link
              }
            }
          }
        }
      }
    }
  }
`;
