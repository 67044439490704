import React from "react";

import { IGetPortfolioForm, TLinksMarkup, TValidationMarkup } from "../../types/commonMarkup.type";

import FooterNavLinks from "./footerNavLinks";
import GetPortfolioForm from "../forms/getPortfolioForm";

const FooterLinksAndForm = ({
  links,
  markup,
  validationMarkup,
  className,
}: {
  links: TLinksMarkup;
  markup: IGetPortfolioForm;
  validationMarkup?: TValidationMarkup;
  className?: string;
}) => {
  return (
    <div className={`flex flex-col gap-y-8 md:gap-y-5 ${className}`}>
      <FooterNavLinks links={links} />

      <div className="h-full flex items-end">
        <GetPortfolioForm markup={markup} validationMarkup={validationMarkup} />
      </div>
    </div>
  );
};

export default FooterLinksAndForm;
