import React from "react";

import { IPoliciesMarkup } from "../../types/commonMarkup.type";
import { Link } from "gatsby";

const PoliciesLinks = ({
  policies: { privacy, cookie },
}: {
  policies: IPoliciesMarkup;
}) => (
  <p className="mt-3 flex flex-col items-start w-full text-secondary-100 text-xs justify-center sm:justify-start">
    <Link
      to={privacy.link}
      title={privacy.title}
      className="text-center hover:brightness-75 -ml-2 px-2 py-1.5"
    >
      {privacy.title}
    </Link>
    <Link
      to={cookie.link}
      title={cookie.title}
      className="text-center hover:brightness-75 -ml-2 px-2 py-1.5"
    >
      {cookie.title}
    </Link>
  </p>
);

export default PoliciesLinks;
