import React from "react";

const ContentContainer = ({
  children,
  className,
  maxWidthClass = "max-w-4.5xl",
}: React.PropsWithChildren<{
  className?: string;
  maxWidthClass?: string;
}>) => {
  return (
    <div
      className={`flex w-full mx-auto px-4 lg:px-10 ${maxWidthClass} ${className}`}
    >
      {children}
    </div>
  );
};

export default ContentContainer;
