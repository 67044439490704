import { FormControlLabel, TextField } from "@mui/material";
import styled from "@emotion/styled";

export const StyledGetPortfolioTextInput = styled(TextField)({
  "& label": {
    color: "#E4E4E4",
    fontFamily: "Montserrat Variable, Helvetica, Arial, sans-serif",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "16px",
  },
  "& label.Mui-focused": {
    color: "#E4E4E4",
  },
  "& label.Mui-error": {
    color: "#E4E4E4",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#2D2D2D",
  },
  "& .MuiOutlinedInput-root": {
    fontFamily: "Montserrat Variable, Helvetica, Arial, sans-serif",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "16px",
    color: "#E4E4E4",
    "&:hover input": {
      borderRadius: "10px",
      backgroundColor: "#2D2D2D",
    },
    "& fieldset": {
      borderColor: "#2D2D2D",
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: "#2D2D2D",
      borderRadius: "10px",
    },

    "&.Mui-focused fieldset": {
      borderColor: "#2D2D2D",
      borderRadius: "10px",
    },
  },
});

export const ContactFormInput = styled(TextField)({
  "& label": {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "16px",
    color: "#111",
    fontFamily: "Montserrat Variable, Helvetica, Arial, sans-serif",
  },
  "& label.Mui-focused": {
    color: "#111",
  },
  "& label.Mui-error": {
    color: "#111",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#2D2D2D",
  },
  "&::placeholder": {
    color: "#111",
  },
  "& .MuiOutlinedInput-root": {
    fontFamily: "Montserrat Variable, Helvetica, Arial, sans-serif",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "16px",
    color: "#111",
    "& fieldset": {
      borderColor: "#2D2D2D",
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: "#2D2D2D",
      borderRadius: "10px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#2D2D2D",
      borderRadius: "10px",
    },
  },
});

export const ControlLabel = styled(FormControlLabel)({
  "& span.MuiFormControlLabel-label": {
    fontSize: "12px",
    lineHeight: "12px",
    color: "#111",
    fontFamily: "Montserrat Variable, Helvetica, Arial, sans-serif",
  },
  "& span.MuiCheckbox-root": {
    padding: "0px 8px 0px 9px",
  },
  "& .MuiSvgIcon-root": { fontSize: 20 },
});
