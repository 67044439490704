import {
  IContactFormMarkup,
  IMarkupQueryResult,
  TValidationMarkup,
} from "./commonMarkup.type";

export interface IContactFromQueryResult
  extends IMarkupQueryResult<{
    contactForm: IContactFormMarkup;
    validation: TValidationMarkup;
  }> {
  en?: { nodes?: { html: string }[] };
  ru?: { nodes?: { html: string }[] };
}

type THubspotFields<T> = {
  [K in keyof T]: {
    objectTypeId: "0-1";
    name: K;
    value: T[K];
  };
}[keyof T][];

export interface IHubspotFormDTO<T> {
  fields: THubspotFields<T>;
  context: {
    hutk?: string;
  };
  legalConsentOptions: {
    consent: {
      consentToProcess: boolean;
      text: string;
    };
  };
}

export interface IGetPortfolioFormData {
  email: string;
  firstname?: string | null;
}

export enum ValidationKeys {
  email = "invalidEmail",
  required = "required",
  consent = "consent",
}

export enum ServicesOptions {
  web_dev = "web_dev",
  mobile_dev = "mobile_dev",
  qa = "qa",
  devops = "devops",
  ui_ux = "ui_ux",
  ba = "ba",
  add_ins = "add_ins",
  vr_ar = "vr_ar",
  support = "support",
  consulting = "consulting",
}

export enum BudgetOptions {
  "_1500_2500" = "_1500_2500",
  "_2500_5000" = "_2500_5000",
  "_5000_10000" = "_5000_10000",
  "_10000_" = "_10000_",
  not_decided = "not_decided",
}

export interface IContactFormData {
  email: string;
  company?: string | null;
  firstname?: string | null;
  services?: ServicesOptions | null;
  budget?: BudgetOptions | null;
  describe_your_project?: string | null;
  consentToProcess: boolean;
}
