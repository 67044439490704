import React, { useEffect, useRef, useState } from "react";

import { FaChevronUp } from "@react-icons/all-files/fa/FaChevronUp";

const UpButton = () => {
  const isInitial = useRef<boolean>(true);
  const [isShow, setIsShow] = useState<boolean>(false);

  const showHideUpButton = () => {
    const isScrolled = window.innerHeight < window.scrollY;

    if (isScrolled) setIsShow(true);
    if (!isScrolled) setIsShow(false);
  };

  useEffect(() => {
    if (!window || !isInitial.current) return;

    isInitial.current = false;
    window.addEventListener("scroll", showHideUpButton);

    return () => window.removeEventListener("scroll", showHideUpButton);
  }, []);

  const onClick = () => {
    if (!window) return;
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      {isShow ? (
        <button
          aria-label="scroll up"
          className="fixed left-[2%] bottom-[5%] z-20 bg-secondary-10 h-14 w-14 rounded-full flex items-center justify-center opacity-50"
          onClick={onClick}
        >
          <FaChevronUp size="25px" />
        </button>
      ) : null}
    </>
  );
};

export default UpButton;
