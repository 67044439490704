import React from "react";

import { FaTelegram } from "@react-icons/all-files/fa/FaTelegram";
import { FaWhatsapp } from "@react-icons/all-files/fa/FaWhatsapp";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import { BsEnvelopeFill } from "@react-icons/all-files/bs/BsEnvelopeFill";
import { Link } from "gatsby";

const ContactLinks = ({
  invert,
  size,
}: {
  invert?: boolean;
  size?: number;
}) => {
  const color = invert ? "white" : "black";
  const iconSize = size ? `${size}px` : "26px";

  const linkClassName = `flex items-center justify-center hover:brightness-75 rounded`;

  return (
    <>
      <Link to="/services#formtarget" title="Email" className={linkClassName}>
        <BsEnvelopeFill size={iconSize} color={color} />
      </Link>
      <a
        href="https://t.me/CodeCaveSupportBot"
        target="_blank"
        rel="noopener noreferrer"
        title="Telegram"
        itemProp="contactOption"
        className={linkClassName}
      >
        <FaTelegram size={iconSize} color={color} />
      </a>
      <a
        href="https://wa.me/13023640193"
        target="_blank"
        rel="noopener noreferrer"
        title="Whatsapp"
        itemProp="contactOption"
        className={linkClassName}
      >
        <FaWhatsapp size={iconSize} color={color} />
      </a>
      <a
        href="https://www.linkedin.com/company/codecave/about/?viewAsMember=true"
        target="_blank"
        rel="noopener noreferrer"
        title="Linkedin"
        itemProp="contactOption"
        className={linkClassName}
      >
        <FaLinkedin size={iconSize} color={color} />
      </a>
    </>
  );
};

export default ContactLinks;
