import * as yup from "yup";
import { ValidationKeys } from "../types/form.type";

export const getPortfolioFormSchema = yup
  .object({
    email: yup
      .string()
      .min(1, ValidationKeys.required)
      .email(ValidationKeys.email)
      .required(ValidationKeys.required),
    firstname: yup.string().nullable(),
  })
  .required();

export const contactFormSchema = yup
  .object({
    email: yup
      .string()
      .min(1, ValidationKeys.required)
      .email(ValidationKeys.email)
      .required(ValidationKeys.required),
    company: yup.string().nullable(),
    firstname: yup.string().nullable(),
    describe_your_project: yup.string().nullable(),
    consentToProcess: yup
      .boolean()
      .oneOf([true], ValidationKeys.consent)
      .required(ValidationKeys.required),
  })
  .required();
